input#q {
	font-size: 12pt;
	line-height: 2;
	width: 100%;
}
input[type=submit] {
	width: 100%;
}
@media screen and (min-width: 640px) {
	input#q {
		width: 66.67%;
	}
	input[type=submit] {
		width: auto;
	}
}
